.not-found-container {
    width: 400px;
    margin: 100px auto;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 100px;
    border-radius: 5px;
    text-align: center;
}

.not-found-container h1 {
    margin-bottom: 40px;
}
.not-found-link {
    width: 100%;
    padding: 10px;
    margin: 10px;
    background-color: #5A4484;
    color: white;
    border: 2px solid #5A4484;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.not-found-link:hover {
    background-color: #AC95D6;
    border: 2px solid #AC95D6;
}